import Amplify, { Auth } from 'aws-amplify';
import React from 'react';
import './App.css';
import gql from 'graphql-tag';
import AWSAppSyncClient from 'aws-appsync';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import { getEmbedUrl } from "./graphql/queries";

Amplify.configure(awsconfig);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: 'Loading...', dashboard: null };
    this.client = new AWSAppSyncClient({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      region: awsconfig.aws_appsync_region,
      auth: {
        type: awsconfig.aws_appsync_authenticationType,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
      }
    });

    this.getDashboardUrl()
  }

  async getDashboardUrl() {
    console.log(this.props)
    this.client.query({
      query: gql(getEmbedUrl),
      variables: {
        //id: "147d79f2-351b-40c0-9383-a4e47ce8523b"
	      id: "7697612d-9212-4f2b-9f52-ea5a7f24e418"
      }
    }).then(({ data: { getEmbedUrl } }) => {
      this.setState({ dashboard: getEmbedUrl.EmbedUrl })
    });
  }

  render() {
    if (this.state.dashboard) {

      const div_style = {
        display: 'flex',
        position: 'absolute',
        top: '67px',
        left: '0',
        right: '0',
        bottom: '0'
      };

      const frame_style = {
        flex: '1',
        border: '0'
      };

      return (
        <div style={div_style}>
          <iframe title='quicksight' style={frame_style} src={this.state.dashboard} seamless />
        </div>
      )
    } else {
      return (
        <p>{this.state.message}</p>
      )
    }
  }
}

export default withAuthenticator(App, {
  signUpConfig: {
    hiddenDefaults: ['phone_number']
  }
}, true);

