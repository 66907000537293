/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmbedUrl = /* GraphQL */ `
  query GetEmbedUrl($id: String!) {
    getEmbedUrl(id: $id) {
      EmbedUrl
      RequestId
    }
  }
`;
