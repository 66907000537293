/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6e544e91-e83b-4f43-bdf3-07a9266caa32",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rWZqZmN8W",
    "aws_user_pools_web_client_id": "1jdg9s43thhldtk259ls4s2sra",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "quicksight-embed-20190730193216-hostingbucket-sandbox",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d14vkhn9q7yifi.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://hiuvygqsi5esxdrxckscumzuc4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
